.App {
  .jumbotron {
    background: url("../assets/images/large_florentin.jpg") no-repeat center center fixed;
    background-size: cover;
    height: 100%;
  }

  .welcome-text {
    font-family: AbsoluteNeonScript, sans-serif;
    font-size: 15rem;
  }

  .our-website-text {
    font-family: IntelligentDesign-ZonaPro-Thin, sans-serif;
  }

  .we-are-logo {
    height: 106px;
    width: 300px;
    display: inline-block;
  }

  .justify-content-md-center {
    p {
      font-family: IntelligentDesign-ZonaPro-Thin, sans-serif;

      span {
        font-family: IntelligentDesign-ZonaPro-Black, sans-serif;
      }

      .footer {
        &-year {
          font-family: IntelligentDesign-ZonaPro-Thin, sans-serif;
        }
      }
    }

    .we-are-text {
      font-family: AbsoluteNeonScript, sans-serif;
      font-size: 10rem;
      display: inline-block;
    }

    .contact-us {
      font-family: IntelligentDesign-ZonaPro-Black, sans-serif;
      text-align: center;
    }

    .footer-initials {
      font-family: IntelligentDesign-ZonaPro-Black, sans-serif;
    }
  }

  .container {
    .card {
      border-style: none;
    }

    .card-title {
      font-family: IntelligentDesign-ZonaPro-SemiBold, sans-serif;
      text-align: center;
    }

    .card-text {
      text-align: center;
    }

    @import "skillbar";
  }
}