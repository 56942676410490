@font-face {
  font-family: AbsoluteNeonScript;
  src: url(../assets/fonts/AbsoluteNeonScript.ttf);
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-Black;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-Black.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-BlackItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-BlackItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-Bold;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-Bold.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-BoldItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-BoldItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-ExtraBold;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-ExtraBold.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-ExtraBoldItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-ExtraBoldItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-Hairline;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-Hairline.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-HairlineItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-HairlineItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-Light;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-Light.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-LightItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-LightItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-Regular;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-Regular.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-RegularItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-RegularItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-SemiBold;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-SemiBold.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-SemiBoldItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-SemiBoldItalic.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-Thin;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-Thin.otf");
}

@font-face {
  font-family: IntelligentDesign-ZonaPro-ThinItalic;
  src: url("../assets/fonts/Intelligent Design - ZonaPro-ThinItalic.otf");
}