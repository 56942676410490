$phone-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;

@import 'fonts';

@mixin phone {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @import 'mobile';
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @import 'tablet';
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @import 'desktop';
  }
}

@include phone;
@include tablet;
@include desktop;