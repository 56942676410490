.skills-bar-container {
  display: block;
  margin: auto;

  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    width: 350px;
  }

  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    width: 400px;
  }

  @media (min-width: #{$desktop-width}) {
    width: 600px;
  }

  .skillbar {
    &-title {
      span {
        background: black;
        font-family: IntelligentDesign-ZonaPro-Thin, sans-serif;
        width: 150px;
      }
    }

    &-bar {
      background: black;
    }

    &-percent {
      font-family: IntelligentDesign-ZonaPro-SemiBold, sans-serif;
      color: white;
    }
  }
}